<template>
    <LiefengContent>
        <template v-slot:title>广州市52个示范社区服务情况</template>
        <template v-slot:toolsbarRight>
            <DatePicker v-model="yearOfMonth" type="month" transfer placeholder="请选择日期" style="width: 200px" format="yyyy-MM" @on-change="changeDate" />
            <LiefengCascaderCity v-if="showCascader" @changeCasader="changeCasader" :resetNum="resetNum" :orgCode="4401" :width="200" :isClear="true"></LiefengCascaderCity>
            <Button type="primary" @click="getList()">查询</Button>
            <Button type="info" @click="resetBtn">重置</Button>
            <Button type="primary" @click="changeGrid">切换图表</Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable :talbeColumns="talbeColumns" :tableData="tableData" :loading="loading" :fixTable="true" :hidePage="true"></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengCascaderCity,
    },
    data() {
        return {
            loading: false,
            //表头列表
            talbeColumns: [
                {
                    title: "区",
                    key: "zone",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "街镇",
                    key: "street",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "社区",
                    key: "community",
                    align: "center",
                    minWidth: 100,
                },
            ],
            tableData: [],
            currentPage: 1,
            pageSize: 20,
            yearOfMonth: "",
            showCascader: true,
            cascaderList: [],
            resetNum: 1, // 是否清空当前社区选择
        }
    },
    async created() {
        await this.getDictTye()
        await this.getList()
    },
    methods: {
        changeParams(obj) {
            // 点击时传递的参数处理，先处理循环对象，再将json字符串的双引号转为单引号。
            var cache = []
            var objs = JSON.stringify(obj, function (key, value) {
                if (typeof value === "object" && value !== null) {
                    if (cache.indexOf(value) !== -1) {
                        return
                    }
                    cache.push(value)
                }
                return value
            }).replace(/\"/g, "'")
            return objs
            // Enable garbage collection
        },
        changeGrid() {
            this.$core.openLayerFrame({
                type: 2,
                title: "广州市52个示范社区服务情况",
                content: `/page#/statisticalgrid`,
                area: ["100%", "100%"],
            })
        },
        changeCasader(val) {
            this.cascaderList = val
        },
        // 重置
        resetBtn() {
            this.resetNum++
            this.cascaderList = []
            this.yearOfMonth = ""
            this.getList()
        },
        // 修改时间
        changeDate(val) {
            this.yearOfMonth = this.$core.formatDate(new Date(val), "yyyy-MM")
        },
        // 导出按钮
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？</p>",
                onOk: () => {},
            })
        },
        getList() {
            this.loading = true
            this.$get("/gateway/api/symanage/pc/o2omin/getMonthData", {
                month: this.yearOfMonth && this.yearOfMonth != "" ? this.$core.formatDate(new Date(this.yearOfMonth), "yyyy-MM") : "",
                orgCode: "",
            }).then(res => {
                this.loading = false
                this.tableData = []
                var rows = {}
                var value = {}
                var tableList = []
                if (res && res.code == 200 && res.dataList) {
                    if (this.cascaderList && this.cascaderList.length) {
                        let code = this.cascaderList[this.cascaderList.length - 1]
                        res.dataList.map(item => {
                            if (item.zoneCode == code || item.streetCode == code || item.communityCode == code) {
                                tableList.push(item)
                            }
                        })
                    } else {
                        tableList = res.dataList
                    }
                    this.tableData = tableList.map(item => {
                        if (item.data && item.data.length) {
                            var totals = 0
                            item.data.map(items => {
                                rows[items.type] = items.value
                                value["totals"] = (value["totals"] || 0) + totals
                                for (var key in items) {
                                    if (key == "value") {
                                        value[items.type] = (value[items.type] || 0) + Number(items.value)
                                    }
                                }
                            })
                            for(var key in rows){
                                totals = totals + (Number(rows[key]) || 0)
                            }
                            return {
                                ...item,
                                ...rows,
                                totals,
                            }
                        } else {
                            return {
                                ...item,
                            }
                        }
                    })
                    if (this.tableData && this.tableData.length != 0) {
                        var rowss = {}
                        var totalss = 0
                        this.tableData.map(item => {
                            totalss = totalss + Number(item.totals || 0)
                        })
                        this.tableData.map(item =>{
                            for(var key in item){
                                for(var keys in rows){
                                    if(key == keys){
                                        rowss[key] = (Number(rowss[key]) || 0 ) + (Number(item[key] || 0))
                                    }
                                }
                            }
                        })
                        this.tableData[this.tableData.length] = {
                            ...value,
                            ...rowss,
                            totals: totalss,
                            community: "合计",
                        }

                        
                    }
                } else {
                    this.tableData = []
                }
            })
        },
        async getDictTye() {
            await this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                appCode: "SUIYUE",
                dictType: "O2O_SERVICE_TYPE",
            }).then(res => {
                if (res.code == 200) {
                    this.selectList = res.dataList.map(item => {
                        this.talbeColumns.push({
                            title: item.dictValue,
                            key: item.dictKey,
                            align: "center",
                            minWidth: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: params.row.community.indexOf('合计') > -1 ? "" : 'blue',
                                            cursor: "pointer",
                                        },
                                        on: {
                                            click: e => {
                                                if (params.row.communityCode && params.row.communityCode != "") {
                                                    this.$core.openLayerFrame({
                                                        type: 2,
                                                        title: "广州市52个示范社区服务情况",
                                                        content: `/page#/situationdetail?obj=${this.changeParams(params.row)}`,
                                                        area: ["100%", "100%"],
                                                    })
                                                }
                                            },
                                        },
                                    },
                                    params.row[item.dictKey]
                                )
                            },
                        })
                    })
                    this.talbeColumns.push({
                        title: "全部",
                        key: "totals",
                        align: "center",
                        minWidth: 100,
                    })
                }
            })
        },
    },
}
</script>
    
<style scoped lang='less'>
</style>